// Generated by Framer (7f988a2)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["T6MnrnYnE"];

const serializationHash = "framer-f7o5c"

const variantClassNames = {T6MnrnYnE: "framer-v-1vei573"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: -10, rotateX: 0, rotateY: 0, scale: 0.9, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 0}

const transition1 = {damping: 20, delay: 0.25, mass: 1, stiffness: 400, type: "spring"}

const animation1 = {opacity: 0, rotate: -10, rotateX: 0, rotateY: 0, scale: 0.9, skewX: 0, skewY: 0, transformPerspective: 1200, transition: transition1, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "T6MnrnYnE", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vei573", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"T6MnrnYnE"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-f7o5c.framer-mba1c3, .framer-f7o5c .framer-mba1c3 { display: block; }", ".framer-f7o5c.framer-1vei573 { height: 404px; overflow: hidden; position: relative; width: 235px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 404
 * @framerIntrinsicWidth 235
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCmE9QsAto: React.ComponentType<Props> = withCSS(Component, css, "framer-f7o5c") as typeof Component;
export default FramerCmE9QsAto;

FramerCmE9QsAto.displayName = "phone";

FramerCmE9QsAto.defaultProps = {height: 404, width: 235};

addFonts(FramerCmE9QsAto, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})